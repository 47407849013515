import logo from "./logo.svg";
import imagen1 from "./imagen1.jpg";
import imagen2 from "./imagen2.webp";
import "./App.css";
//Importamos la funcion usar estado
import { useState } from "react";
import axios from "axios";

function App() {
  // return [ estado, funcion para cmabir el estado ]
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showResponse, setShowResponse] = useState("");
  async function enterFormulario(e) {
    e.preventDefault();
    console.log(email);
    console.log(password);
    /**Cliente -> Servidor */
    try {
      const respuesta = await axios.post(
        "https://sameer-kumar-aztro-v1.p.rapidapi.com/?sign=" +
          email +
          "&day=" +
          password,
        {},
        {
          headers: {
            "x-rapidapi-key":
              "1456853350msh27502ee36941f70p136372jsn41792a98fa16",
            "x-rapidapi-host": "sameer-kumar-aztro-v1.p.rapidapi.com",
          },
        }
      );
      setShowResponse(respuesta.data.description);
      console.log(respuesta.data);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div className="App">
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
          <a class="navbar-brand" href="#">
            Navbar
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#">
                  Home
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  Link
                </a>
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Dropdown
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a class="dropdown-item" href="#">
                      Action
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Another action
                    </a>
                  </li>
                  <li>
                    {" "}
                    <hr class="dropdown-divider" />{" "}
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Something else here
                    </a>
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link disabled"
                  href="#"
                  tabindex="-1"
                  aria-disabled="true"
                >
                  Disabled
                </a>
              </li>
            </ul>
            <form class="d-flex">
              <input
                class="form-control me-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
              <button class="btn btn-outline-success" type="submit">
                Search
              </button>
            </form>
          </div>
        </div>
      </nav>

      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>

      <form onSubmit={(e) => enterFormulario(e)}>
        <label>Escribe tu correo</label>
        <input
          className="form-control"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          type="text"
        />
        <label>Escribe tu contraseña</label>
        <input
          value={password}
          type="text"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <button type="submit" className="btn btn-success">
          Enviar
        </button>
      </form>

      <p> El correo: {email} </p>
      <p> El password: {password} </p>
      <div class="alert alert-primary" role="alert">
        A simple primary alert—check it out!
      </div>
      <p>{showResponse}</p>
    </div>
  );
}

export default App;
